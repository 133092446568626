<template>
  <div class="content-container">
    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Playground</h1>
            </div>
          </div>
        </section>
        <section>
          <Card />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../../components/ds-components/Card.vue";
export default {
  name: "Playground",
  components: {
    Card,
  },
};
</script>
