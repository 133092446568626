<template>
  <a href="#" target="_blank" class="card white external hover-shadow"
    ><div class="card__img">
      <img src="/img/photo_2.d93d755f.jpg" alt="" class="img-cover" />
    </div>
    <div class="card__content">
      <header>
        <div class="card__title semibold">
          Lorem ipsum dolor sit amet cons ectetur adipiscing elit sed
        </div>
      </header>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incid idunt ut. dolore magna aliqua. Sed do eiusm od tempor
        incididunt ut.
      </p>
    </div></a
  >
</template>

<script>
export default {
  name: "Card",
};
</script>
